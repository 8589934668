import React from 'react';
import { DateRange } from 'react-date-range';
import addDays from 'date-fns/addDays'
import differenceInCalendarDays from 'date-fns/differenceInCalendarDays'
import csLocale from 'date-fns/locale/cs';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';

import { useWindowSize } from "../model/hooks"
import '../assets/custom.css'

const TOGGLE_WINDOW_WIDTH = 1080;

const disabledDates = (() => {
  const out = [];

  for (let i = 0; i < 7; i++) {
    out.push(addDays(new Date('2020-06-20'), i));
  }

  for (let i = 0; i < 5; i++) {
    out.push(addDays(new Date('2020-06-28'), i));
  }

  for (let i = 0; i < 3; i++) {
    out.push(addDays(new Date('2020-07-10'), i))
  }

  for (let i = 0; i < 4; i++) {
    out.push(addDays(new Date('2020-07-13'), i));
  }

  for (let i = 0; i < 2; i++) {
    out.push(addDays(new Date('2020-07-17'), i));
  }

  for (let i = 0; i < 3; i++) {
    out.push(addDays(new Date('2020-07-23'), i));
  }

  for (let i = 0; i < 14; i++) {
    out.push(addDays(new Date('2020-07-26'), i))
  }

  for (let i = 0; i < 2; i++) {
    out.push(addDays(new Date('2020-08-21'), i));
  }

  for (let i = 0; i < 3; i++) {
    out.push(addDays(new Date('2020-09-25'), i));
  }

  for (let i = 0; i < 2; i++) {
    out.push(addDays(new Date('2020-08-28'), i));
  }

  for (let i = 0; i < 2; i++) {
    out.push(addDays(new Date('2020-06-05'), i));
  }

  for (let i = 0; i < 7; i++) {
    out.push(addDays(new Date('2020-08-08'), i));
  }

  for (let i = 0; i < 2; i++) {
    out.push(addDays(new Date('2020-09-18'), i));
  }

  return out;
})()

const MIN_DATE = new Date();
const MAX_DATE = new Date('2020-10-30');

const Calendar = props => {
  // TODO: Na malych displayech zobrazovat jen 1 kalendar
  // TODO: Lokalizace
  const [numberOfMonths, setNumberOfMonths] = React.useState(3);
  const [state, setState] = React.useState([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: 'selection'
    }
  ]);
  const [numberOfSelectedDays, setNumberOfSelectedDays] = React.useState(undefined)

  const onSelectionChange = (item) => {
    const selection = item.selection
    const numberOfDays = differenceInCalendarDays(selection.endDate, selection.startDate) + 1

    setNumberOfSelectedDays(numberOfDays)
    setState([selection]);

    if (typeof props.onSelection !== 'undefined') {
      props.onSelection({
        ...selection,
        numberOfDays,
      })
    }
  }

  const { width } = useWindowSize();
  React.useEffect(() => {
    if (width < TOGGLE_WINDOW_WIDTH && numberOfMonths === 3) {
      setNumberOfMonths(1);
      return;
    }

    if (width >= TOGGLE_WINDOW_WIDTH && numberOfMonths === 1) {
      setNumberOfMonths(3);
    }
  }, [width])

	return (
	  <>
      <DateRange
        locale={ csLocale }
        onChange={ onSelectionChange }
        showSelectionPreview={true}
        moveRangeOnFirstSelection={false}
        months={ numberOfMonths }
        ranges={state}
        direction="horizontal"
        disabledDates={ disabledDates }
        showDateDisplay={ false }
        showMonthAndYearPickers={ false }
        color={ '#9eadff' }
        rangeColors={[ '#9eadff', ]}
      />

      {typeof numberOfSelectedDays !== 'undefined' &&
        <div className={`flex justify-center ${numberOfSelectedDays < 3 ? 'text-red-600' : 'text-gray-700'}`}>
          Dní: { numberOfSelectedDays } | Nocí: {numberOfSelectedDays - 1}

          {numberOfSelectedDays < 3 &&
            <>
              <br/>
              Minimální počet nocí: 2
            </>
          }
        </div>
      }
    </>
	);
};

export default Calendar;
