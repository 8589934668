import React from "react"
import Carousel, { Modal, ModalGateway } from "react-images"
import GridGallery from "react-grid-gallery"

const gutter = 3;

const Gallery = ({ images }) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const [selectedIndex, setSelectedIndex] = React.useState(0)

  const toggleLightbox = () => setIsOpen(! isOpen)

  const imageClicked = (index) => {
    setIsOpen(true);
    setSelectedIndex(index);
  }

  return (
    <>
      <GalleryDiv>
        { images.map((image, index) => (
          <Image
            onClick={ () => imageClicked(index) }
            key={ index }
          >
            <img
              alt=""
              src={ image.source.regular }
              style={{
                cursor: 'pointer',
                position: 'absolute',
                maxWidth: '100%',
              }}
            />
          </Image>
        ))}
      </GalleryDiv>

      <ModalGateway>
        {isOpen ? (
          <Modal onClose={toggleLightbox} allowFullscreen={ false }>
            <Carousel
              currentIndex={selectedIndex}
              views={ images }
            />
          </Modal>
        ) : null}
      </ModalGateway>
    </>
  )
}

const GalleryDiv = (props) => (
  <div
    style={{
      overflow: 'hidden',
      marginLeft: -gutter,
      marginRight: -gutter,
    }}
    {...props}
  />
);

const Image = (props) => (
  <div
    style={{
      // backgroundColor: '#eee',
      boxSizing: 'border-box',
      float: 'left',
      margin: gutter,
      overflow: 'hidden',
      paddingBottom: '16%',
      position: 'relative',
      width: `calc(25% - ${gutter * 2}px)`,

      ':hover': {
        opacity: 0.9,
      },
    }}
    {...props}
  />
);

export default Gallery
