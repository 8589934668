import React from 'react';

const PriceList = props => {
	return (
		<>
      <div className="max-w-sm mx-auto flex justify-center p-6 bg-white rounded-lg hover:shadow-sm">
        <div className="pt-1">
          <h2 className="text-2xl text-center leading-tight">
            Ceník
          </h2>
          <h4 className="text-lg text-center text-gray-900 leading-tight mt-3">
          Chata se pronajímá jako celek.
          </h4>
          <h4 className="text-lg text-center text-gray-900 leading-tight mt-3">
            2-3 noci 6 000,- Kč chata/noc<br></br> přistýlka 300,- Kč
          </h4>
          <h4 className="text-lg text-center text-gray-900 leading-tight mt-3">
            4 a více nocí 5 000,- Kč chata/noc<br></br> přistýlka 300,- Kč
          </h4>
          <p className="text-base text-gray-700 text-center leading-normal mt-3">
            Poplatek obci: 40 Kč/den
          </p>
          <p className="text-base text-gray-700 text-center leading-normal mt-3">
            Příjezd od 15-18 hodin, odjezd do 12 hodin
          </p>
          <p className="text-base text-gray-700 text-center leading-normal mt-3">
            Nevratná záloha 20% z ceny pobytu
          </p>
          <p className="text-base text-gray-700 text-center leading-normal mt-3">
            Vratná kauce 3 000 Kč
          </p>
          <p className="text-base text-gray-700 leading-normal mt-3">
            Rezervace na: <a href="https://www.e-chalupy.cz/beskydy/ubytovani-pozlovice-chata-ruha-pronajem-7887.php" target="_blank"> e-chalupy.cz</a>
          </p>
        </div>
      </div>
		</>
	);
};

export default PriceList;
