import React from "react"
import { Formik, Form, Field } from "formik"
import * as Yup from "yup"

const FormValidation = Yup.object().shape({
  email: Yup.string().required().email(),
  name: Yup.string().required()
})

const ContactForm = props => {
  const submit = (values) => {
    props.submit(values)
  }

  return (
    <>
      <Formik
        initialValues={{
          email: "", name: "", text: ""
        }}
        onSubmit={submit}
        validationSchema={FormValidation}
      >
        {() => (
          <div className="w-full">
            <div className="bg-white rounded px-8 pt-6 pb-8 mb-4 text-left">

              <Form>
                <div className="flex flex-wrap mb-4">
                  <div className="sm:w-full md:w-1/2 px-3">
                    <Field name="email">
                      {({ field, meta }) => {
                        return (
                          <>
                            <label
                              className="text-gray-700 text-sm font-bold mb-2"
                              htmlFor="email-input"
                            >
                              E-mail
                              {meta.touched && meta.error &&
                              <span className="text-red-500"> (je nutné vyplnit validní email)</span>
                              }
                            </label>
                            <input
                              id="email-input"
                              className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                              type="text"
                              {...field}
                            />
                          </>
                        )
                      }}
                    </Field>
                  </div>
                  <div className="sm:w-full md:w-1/2 px-3">
                    <Field name="name">
                      {({ field, meta }) => (
                        <>
                          <label
                            className="text-gray-700 text-sm font-bold mb-2"
                            htmlFor="name-input"
                          >
                            Jméno a příjmení
                            {meta.touched && meta.error &&
                            <span className="text-red-500"> (povinné pole)</span>
                            }
                          </label>
                          <input
                            id="name-input"
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            type="text"
                            {...field}
                          />
                        </>
                      )}
                    </Field>
                  </div>
                </div>
                <div className="flex mt-8 mb-8">
                  <div className="w-full px-3">
                    <Field name="text">
                      {({ field }) => (
                        <>
                          <label
                            className="block text-gray-700 text-sm font-bold mb-2"
                            htmlFor="text-input"
                          >
                            Zpráva
                          </label>
                          <textarea
                            rows={3}
                            placeholder="Vaše místo pro dotazy, poznámky k rezervaci,..."
                            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                            {...field}
                          >
                          </textarea>
                        </>
                      )}
                    </Field>
                  </div>
                </div>
                <div className="flex justify-center mt-12 mb-4">
                  <button className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded" type="submit">
                    Odeslat
                  </button>
                </div>
              </Form>
            </div>
          </div>
        )}
      </Formik>
    </>
  )
}

export default ContactForm
