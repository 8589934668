import React from "react"
import { graphql, Link, useStaticQuery } from "gatsby"

import * as constants from "../model/constats"
import Gallery from "../components/gallery"
import Footer from "./footer"
import NavMobile from "./navMobile"
import { faParking } from "@fortawesome/free-solid-svg-icons/faParking"
import { faWifi } from "@fortawesome/free-solid-svg-icons/faWifi"
import { faBed } from "@fortawesome/free-solid-svg-icons/faBed"
import { faRestroom } from "@fortawesome/free-solid-svg-icons/faRestroom"
import { faHome } from "@fortawesome/free-solid-svg-icons/faHome"
import { faSwimmingPool } from "@fortawesome/free-solid-svg-icons/faSwimmingPool"
import { faHotTub } from "@fortawesome/free-solid-svg-icons/faHotTub"
import { faUtensils } from "@fortawesome/free-solid-svg-icons/faUtensils"
import { faWalking } from "@fortawesome/free-solid-svg-icons/faWalking"
import { faBiking } from "@fortawesome/free-solid-svg-icons/faBiking"
import IconsList from "./iconsList"
import { faUtensilSpoon } from "@fortawesome/free-solid-svg-icons/faUtensilSpoon"
import { imageSharpQueryToImgObjects } from "../model/helpers"
import { faSpa } from "@fortawesome/free-solid-svg-icons/faSpa"
import Reservation from "./reservation"
import GoogleMap from "./googleMap"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export const Test = () => {
  const [menuOpen, setMenuOpen] = React.useState(false)

  const data = useStaticQuery(graphql`
      query HeroImageQuery {
          heroImage: allFile(filter: {name: {eq: "heroImage"}}) {
              edges {
                  node {
                      childImageSharp {
                          fluid {
                              src
                          },
                          fixed(quality: 100, width: 1000, jpegQuality: 90) {
                              src
                          }
                      }
                  }
              }
          },
          allFile(filter: {relativeDirectory: {eq: "homepage"}}) {
              edges {
                  node {
                      childImageSharp {
                          fluid {
                              src
                          },
                          fixed(quality: 90) {
                              src
                          }
                      }
                  }
              }
          }
      }
  `)

  const imgSrc = data.heroImage.edges[0].node.childImageSharp.fixed.src;
  const imgs = imageSharpQueryToImgObjects(data.allFile.edges);

  const icons = [
    { icon: faBed, label: "Ubytovací kapacita 12 lidí (po domluvě až 16)" },
    { icon: faHome, label: "4x pokoj & 2x apartmán" },
    { icon: faUtensilSpoon, label: "Vybavená kuchyně"},
    { icon: faParking, label: "Parkování až pro 6 aut na oploceném pozemku" },
    { icon: faWifi, label: "WIFI internet" },
    { icon: faRestroom, label: "Toaleta / Koupelna v každém pokoji" },
    { icon: faSwimmingPool, label: "V blízkosti přehrada a koupaliště" },
    { icon: faHotTub, label: "V blízkosti wellness" },
    { icon: faUtensils, label: "V blízkosti restaurace" },
    { icon: faSpa, label: "V blízkosti lázně Luhačovice" },
    { icon: faWalking, label: "Pěší trasy v okolí" },
    { icon: faBiking, label: "Cyklistické trasy v okolí" },
  ]
  const toggleMenu = () => setMenuOpen(!menuOpen)

  return (
    <>
      <div className="relative bg-white overflow-hidden">
        <div className="max-w-screen-xl mx-auto ">
          <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
            <svg
              className="hidden lg:block absolute right-0 inset-y-0 h-full w-48 text-white transform translate-x-1/2"
              fill="currentColor"
              viewBox="0 0 100 100"
              preserveAspectRatio="none"
            >
              <polygon points="50,0 100,0 50,100 0,100"/>
            </svg>

            <div className="relative pt-6 px-4 sm:px-6 lg:px-8">
              <nav className="relative flex items-center justify-between sm:h-10 lg:justify-start">
                <div className="flex items-center flex-grow flex-shrink-0 lg:flex-grow-0">
                  <div className="flex items-center justify-between w-full md:w-auto">
                    <div className="-mr-2 flex items-center md:hidden">
                      <button
                        onClick={toggleMenu}
                        type="button"
                        className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-600 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-600 transition duration-150 ease-in-out"
                      >
                        <svg
                          className="h-6 w-6"
                          stroke="currentColor"
                          fill="none"
                          viewBox="0 0 24 24"
                        >
                          <path
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            stroke-width="2"
                            d="M4 6h16M4 12h16M4 18h16"
                          />
                        </svg>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="hidden md:block md:ml-10 md:pr-4">
                  {constants.NAV_ITEMS.map((item, index) => (
                    <Link
                      key={index}
                      className={`${index === 0 ? "" : "ml-8"} font-medium text-gray-600 hover:text-gray-900 focus:outline-none focus:text-gray-900 transition duration-150 ease-in-out`}
                      to={item.to}
                    >
                      {item.name}
                    </Link>
                  ))}
                  <Link
                    to="/rezervace"
                    className="ml-8 font-medium text-red-600 hover:text-red-900 focus:outline-none focus:text-red-700 transition duration-150 ease-in-out"
                  >Rezervace & Kontakt</Link>
                </div>
              </nav>
            </div>

            {menuOpen &&
            <NavMobile toggleMenu={toggleMenu}/>
            }

            <div className="mt-10 mx-auto max-w-screen-xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
              <div className="sm:text-center lg:text-left">
                <h2 className="text-4xl tracking-tight leading-10 font-extrabold text-gray-900 sm:text-5xl sm:leading-none md:text-6xl">
                  Chata
                  <br className="xl:hidden"/>
                  <span className="text-red-600">Luhačovice</span>
                </h2>
                <p className="mt-3 text-base text-gray-600 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                  Zveme vás do prostorné chaty s 6 pokoji a velkou společenskou místností. Chata se nachází v obci Pozlovice u
                  Luhačovic asi 350 m od přehrady a 1,5 km od lázeňského centra Luhačovic v krásném prostředí CHKO Bílé
                  Karpaty. Chata je k pronájmu jako celek a nabízí možnost ubytování až pro 16 osob.

                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="lg:absolute lg:inset-y-0 lg:right-0 lg:w-1/2">
          <img
            className="h-56 w-full object-cover sm:h-72 md:h-96 lg:w-full lg:h-full"
            src={ imgSrc }
            alt=""
          />
        </div>
      </div>

      <div className="mt-5 py-12 bg-white">
        <div className="max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="lg:text-left">
            {/*<p className="mt-4 max-w-2xl text-xl leading-7 text-gray-600 lg:mx-auto">*/}
            <IconsList icons={ icons } />
            {/*</p>*/}
          </div>
          <div className="mt-12">
            <ul className="md:grid md:grid-cols-2 md:col-gap-8 md:row-gap-10">
              <li>
                <div className="flex">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center h-12 w-12 rounded-md bg-red-500 text-white">
                      <FontAwesomeIcon icon={ faBed } />
                    </div>
                  </div>
                  <div className="ml-4">
                    <h5 className="text-lg leading-6 font-medium text-gray-900">Ubytování</h5>
                    <p className="mt-2 text-base leading-6 text-gray-600">
                      Kapacita 12 až 16 osob.<br></br>
                      4x dvojlůžkový pokoj s WC a sprchovým koutem<br></br>
                      2x dvojlůžkový apartmán (2 místnosti) s možností přistýlky, WC s vanou<br></br>
                      Všechny pokoje mají vlastní balkón. Lůžkoviny jsou v ceně (bez ručníků). V přízemí je velká společenská místnost s TV, jídelnou a plně vybavená
                      společná kuchyňka. Před chatou je kryté venkovní posezení s přenosným grilem.
                    </p>
                  </div>
                </div>
              </li>
              <li className="mt-10 md:mt-0">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <div className="flex items-center justify-center h-12 w-12 rounded-md bg-red-500 text-white">
                      <FontAwesomeIcon icon={ faSpa } />
                    </div>
                  </div>
                  <div className="ml-4">
                    <h5 className="text-lg leading-6 font-medium text-gray-900">V okolí chaty</h5>
                    <p className="mt-2 text-base leading-6 text-gray-600">
                      Objekt se nachází v obci Pozlovice u Luhačovic asi 2 minuty chůze od přehrady a
                      přibližně 15 minut od kolonády lázeňského města Luhačovice. V blízkosti je hřiště na minigolf,
                      tenisové kurty, koupaliště, dětská hřiště, lanové centrum a množství stánků a restaurací. Na své
                      si přijdou
                      také milovníci wellness, turistiky, rybaření, cyklistiky, inline bruslení a mnoha dalších aktivit.
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <Gallery images={ imgs }/>

      <Reservation />

      <Footer/>
    </>

  )
}
