import React from "react"
import isBefore from "date-fns/isBefore"

import PriceList from "./priceList"
import Calendar from "./calendar"
import ContactForm from "./contactForm"
import Alert from "../components/alert"

const Reservation = props => {
  const [dateRange, setDateRange] = React.useState(undefined)
  const [alert, setRawAlert] = React.useState(undefined)

  const setAlert = (type) => {
    switch (type) {
      case "error":
        setRawAlert({
          type: "error",
          message: "Došlo k chybě, kontaktujte nás prosím pomocí e-mailového kontaktu v zápatí stránky",
        })
        break

      case "success":
        setRawAlert({
          type,
          message: "Odesláno! Do 24 hodin Vás budeme kontaktovat s dalším postupem",
        })
        break

      case "no-date-range":
        setRawAlert({
          type: "error",
          message: "Prosím vyberte v kalendáři datum rezervace",
        })
        break

      case "date-range-before-today":
        setRawAlert({
          type: "error",
          message: "Datum rezervace nesmí začínat ani končit v minulosti",
        });
        break;

      case "date-range-min-3-days":
        setRawAlert({
          type: 'error',
          message: 'Minimální délka pobytuje je 3 noci',
        })
    }
  }

  const formSubmitted = (values) => {
    console.log({
      dateRange,
    })


    if (typeof dateRange === "undefined" || dateRange.endDate === "undefined" || dateRange.startDate === "undefined") {
      return setAlert('no-date-range');
    }

    if (
      isBefore(dateRange.endDate, new Date())
      || isBefore(dateRange.startDate, new Date())
    ) {
      return setAlert('date-range-before-today');
    }

    if (dateRange.numberOfDays < 3) {
      return setAlert('date-range-min-3-days');
    }

    window.fetch("/api/reservation", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        ...values,
        startDate: dateRange.startDate.toISOString(),
        endDate: dateRange.endDate.toISOString()
      })
    }).then(() => setAlert("success"))
      .catch(() => setAlert("error"))
  }

  return (
    <>
      {alert &&
      <div className="container mx-auto mt-5">
        <Alert
          alert={alert}
          clear={() => setRawAlert(null)}
        />
      </div>
      }

      <div className="container mx-auto mt-5">
        <PriceList/>
      </div>

{/*       <div className="mt-5 mx-auto text-center">
        <Calendar onSelection={setDateRange}/>
      </div>

      <div className="mt-5 mx-auto text-center">
        <ContactForm submit={formSubmitted}/>
      </div>
 */}    </>
  )
}

export default Reservation
