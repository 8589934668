import React from "react"
import { NAV_ITEMS } from "../model/constats"
import { Link } from "gatsby"
import { useOutsideClick } from "../model/hooks"

const NavMobile = props => {
  const ref = React.useRef()

  useOutsideClick(ref, props.toggleMenu)

  return (
    <div
      className="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
      style={{ height: "500px" }}
    >
      <div className="rounded-lg shadow-md">
        <div className="rounded-lg bg-white shadow-xs overflow-hidden" ref={ ref }>
          <div className="px-5 pt-4 flex items-center justify-between">
            <div>
              <img
                className="h-8 w-auto"
                src="/img/logos/workflow-mark-on-white.svg"
                alt=""
              />
            </div>
            <div className="-mr-2">
              <button
                onClick={props.toggleMenu}
                type="button"
                className="inline-flex items-center justify-center p-2 rounded-md text-gray-400 hover:text-gray-600 hover:bg-gray-100 focus:outline-none focus:bg-gray-100 focus:text-gray-600 transition duration-150 ease-in-out"
              >
                <svg
                  className="h-6 w-6"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
          </div>
          <div
            className="px-2 pt-2 pb-3"
            onClick={props.toggleMenu}
          >
            {NAV_ITEMS.map((item, index) => (
              <Link
                key={index}
                to={item.to}
                className={`${index === 0 ? "" : "mt-1"} block px-3 py-2 rounded-md text-base font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-50 focus:outline-none focus:text-gray-900 focus:bg-gray-50 transition duration-150 ease-in-out`}
                activeClassName="text-red-400"
              >
                {item.name}
              </Link>
            ))}
          </div>
          <div>
            <Link
              to="/rezervace"
              className="block w-full px-5 py-3 text-center font-medium text-red-600 bg-gray-50 hover:bg-gray-100 hover:text-red-700 focus:outline-none focus:bg-gray-100 focus:text-red-700 transition duration-150 ease-in-out"
            >
              Rezervace & Kontakt
            </Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default NavMobile
