export const NAV_ITEMS = [
  {
    to: '/',
    name: 'Domů',
  },
  {
    to: '/galerie',
    name: 'Galerie',
  },
];

export const TEMP_IMAGES = [
  {
    source: {
      regular: "https://images.unsplash.com/photo-1449844908441-8829872d2607?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1000&q=50",
      thumbnail: undefined,
    },
  },
  {
    source: {
      regular: "https://images.unsplash.com/photo-1449844908441-8829872d2607?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1000&q=50",
      thumbnail: undefined,
    },
  },
  {
    source: {
      regular: "https://images.unsplash.com/photo-1449844908441-8829872d2607?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1000&q=50",
      thumbnail: undefined,
    },
  },
  {
    source: {
      regular: "https://images.unsplash.com/photo-1449844908441-8829872d2607?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1000&q=50",
      thumbnail: undefined,
    },
  },
]
