import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const IconsList = ({ icons }) => {
	return (
    <div className="flex flex-wrap sm:justify-start md:justify-center lg:justify-center mx-auto text-gray-700">
      { icons.map((icon, key) => (
        <div className="ml-3" key={ key }>
          <FontAwesomeIcon icon={icon.icon}/> {icon.label}
        </div>
      ))}
    </div>
	);
};

export default IconsList;
