import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faMapPin } from "@fortawesome/free-solid-svg-icons/faMapPin"

const Footer = props => {
	return (
		<>
			<div className="mt-20 mb-10">

				<hr />

				<div className="container mx-auto p-10">
					<div className="flex flex-wrap justify-center text-center">
						<div className="sm:w-full md:w-1/3">
							<h4 className="text-gray-900 font-bold text-xl">
								Kontakty
							</h4>

							<p className="text-gray-600">
								Chata Luhačovice
								<br /><a href="mailto:ch.luhacovice@gmail.com"> ch.luhacovice@gmail.com</a>
								<br /><a href="tel:+420603215396"> +420 603 215 396</a>
								<br /><a href="tel:+420603446641"> +420 603 446 641</a>
							</p>
						</div>
						<div className="sm:w-full md:w-1/3">
							<h4 className="text-gray-900 font-bold text-xl">
								Adresa
							</h4>
							<p className="text-gray-600">
								A.Václavíka ev.35<br /> 763 26 Pozlovice 
								
							</p>

							<a
								target="_blank"
								href="https://www.google.com/maps/place/Chata+Luha%C4%8Dovice/@49.1192486,17.7674606,17z/data=!3m1!4b1!4m5!3m4!1s0x47136fbc08b536f1:0x855f9e76ee39faec!8m2!3d49.1192486!4d17.7696493"
								className="mt-3 mb-6 bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded inline-flex items-center"
							>
								<FontAwesomeIcon icon={ faMapPin } />
								<span className="ml-3">
									Mapa
								</span>
							</a>
						</div>
						<div className="sm:w-full md:w-1/3 sm:mt-5">
							<h4 className="text-gray-900 font-bold text-xl">
								© ChataLuhačovice.cz
							</h4>
						</div>
					</div>
				</div>
			</div>

		</>
	);
};

export default Footer;
